import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Fi'l Mudhari`}</strong>{` Or `}<strong parentName="p" {...{
        "className": "firstword arabic"
      }}>{`فعل مضارع`}</strong>{` is used to express an action in the present or an action that will happen in the future.`}</p>
    <h2>{`How to identify Fi'l Mudhari in Arabic Grammar`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`ما علامة فعل مضارع`}</strong></p>
    <br />
    <ul>
      <li parentName="ul">{`فعل مضارع `}<strong parentName="li">{`Always`}</strong>{` begins with words ن – أ - ي - ت which is also called as `}<strong parentName="li">{`أحرف المضارعة`}</strong>{` which is put in one-word نأيت. One point to note is these are `}<em parentName="li">{`NOT 100% sure علامات`}</em>{` of فعل مضارع because there are nouns also that begin with ن – أ - ي - ت.`}</li>
      <li parentName="ul">{`If we can put لم in front of a verb, then it is فعل مضارع`}</li>
    </ul>
    <h2>{`Characteristics of Fi'l Mudhari`}</h2>
    <p>{`Fi'l Mudhari is Mo'rab (معرب) and has following 3 states`}</p>
    <h3>{`الرفع`}</h3>
    <p>{`Fi'l Mudhari is in Raf form when it is not preceded with a harf that will make it Nasb or Jazm`}</p>
    <blockquote>
      <p parentName="blockquote">{`يُرْفَعُ الفعل المضارع إذا لم يسبقْه ناصبٌ ولا جازمٌ`}</p>
    </blockquote>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul">{`يكت`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`بُ`}</strong>{` الطالب الدرس`}</li>
      <li parentName="ul">{`لا يكت`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`بُ`}</strong>{` الطالب الدرس`}</li>
    </ul>
    <h3>{`نصب`}</h3>
    <p>{`Fi'l Mudhari is in Nasb form when it is preceded with a harf that will make it Nasb.`}</p>
    <blockquote>
      <p parentName="blockquote">{`ينصب الفعل المضارع إذا سبقه ناصب`}</p>
    </blockquote>
    <p>{`Example: لن يكت`}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`بَ`}</strong>{` الطالب الدرس`}</p>
    <h3>{`جزم`}</h3>
    <p>{`Fi'l Mudhari is in Jazm form when it is preceded with a harf that will make it Jazm.`}</p>
    <blockquote>
      <p parentName="blockquote">{`يجزم الفعل المضارع إذا سبقه جازم`}</p>
    </blockquote>
    <p>{`Example: لم يكت`}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`بْ`}</strong>{` الطالب الدرس`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Exception:`}</strong>{` In the following two cases, Fi'l Mudhari is not Mo'rab.`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 1:`}</strong>{` When نون النسوة is added to it (إذا اتصلت به نون النسوة), then it is Mabni with Sukoon (يبنى على السكون)`}</p>
    <ul>
      <li parentName="ul">{`الطالبات `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يكتبْنَ`}</strong>{` الدرس`}</li>
      <li parentName="ul">{`الطالبات لن `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يكتبْن`}</strong>{` الدرس`}</li>
      <li parentName="ul">{`الطالبات لم `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يكتبْن`}</strong>{` الدرس`}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`يكتبْن`}</strong>{`
فعل مضارع مبني على السكون لاتصاله بنون النسوة`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 2:`}</strong>{` When نون التوكيد is added to it (إذا اتصلت به نون التوكيد), then it is Mabni with Fatha (يبنى على الفتح)`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`لأكتبَنَّ`}</strong>{` الدرس غداً`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Rule`}</strong>{`  `}</p>
    <blockquote>
      <p parentName="blockquote">{`الفعل المضارع معرب إلا إذا اتصلت به نون النسوة أو نون التوكيد`}</p>
    </blockquote>
    <br />
    <p>{`The I'rab of Fi'l Mudhari gets tricky when it has Harf Illa (و,ا, ي) in the end. Let's look into it one by one,`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 1`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`When الألف comes at the end (فعل مضارع معتل الآخر بالألف)`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يرضى`}</strong>{` الله عن المؤمنين`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`الرفع`}</strong>{`: تقدر عليه الضمة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يرضى`}</strong>{`
فعل مضارع مرفوع لأنه لم يسبقه ناصب ولا جازم وعلامة رفعه الضمة المقدرة على  الألف منع من ظهورها التعذر`}<br parentName="p"></br>{`
`}{`where التعذر means Impossibile to add damma`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`لن `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يرضى`}</strong>{` الله عن الكافرين`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`النصب`}</strong>{`: تقدر الفتحة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يرضى`}</strong>{`
فعل مضارع منصوب وعلامة نصبه الفتحة المقدرة منع من ظهورها التعذر`}<br parentName="p"></br>{`
`}{`where التعذر means Impossibile to add fatha`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`لم `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يرضَ`}</strong>{` الله عن الكافرين`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`الجزم`}</strong>{`: يحذف حرف العلة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يرضَ`}</strong>{`
(فعل مضارع مجزوم وعلامة جزمه حذف حرف العلة (الألف`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 2`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`When الياء comes at the end (فعل مضارع معتل الآخر بالياء)`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يمشيْ`}</strong>{` الطالب إلى البيت`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`الرفع`}</strong>{`: تقدر عليه الضمة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يمشيْ`}</strong>{`
فعل مضارع مرفوع لأنه لم يسبقه ناصب ولا جازم وعلامة رفعه الضمة المقدرة على الياء منع من ظهورها الثقل`}<br parentName="p"></br>{`
`}{`where الثقل is Heaviness meaning its difficult to add damma`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`لن `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يمشيَ`}</strong>{` الطالب إلى البيت`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`النصب`}</strong>{`: تظهر الفتحة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يمشيَ`}</strong>{`
فعل مضارع منصوب ب (لن) وعلامة نصبه الفتحة الظاهرة`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`لم `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يمشِ`}</strong>{` الطالب إلى البيت`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`الجزم`}</strong>{`: يحذف حرف العلة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يمشِ`}</strong>{`
(فعل مضارع مجزوم وعلامة جزمه حذف حرف العلة (الياء`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{` `}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 3`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`When الواو comes at the end (فعل مضارع معتل الآخر بالواو)`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يدعُوْ`}</strong>{` المؤمن إلى الخير`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`الرفع`}</strong>{`: تقدر عليه الضمة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يدعُوْ`}</strong>{`
فعل مضارع مرفوع لأنه لم يسبقه ناصب ولا جازم وعلامة رفعه الضمة المقدرة على الواو منع من ظهورها الثقل`}<br parentName="p"></br>{`
`}{`where الثقل is Heaviness meaning its difficult to add damma`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`لن `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يدعوَ`}</strong>{` المؤمن إلى الشر`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`النصب`}</strong>{`: تظهر الفتحة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يدعوَ`}</strong>{`
فعل مضارع منصوب ب (لن) وعلامة نصبه الفتحة الظاهرة`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`لم `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يدعُ`}</strong>{` المؤمن إلى الشر`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`الجزم`}</strong>{`: يحذف حرف العلة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يدعُ`}</strong>{`
(فعل مضارع مجزوم وعلامة جزمه حذف حرف العلة(الواو`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`That was quite a lot !!! I know, but`}</p>
    <br />
    <p><strong parentName="p">{`Have you ever wondered what makes Fi'l Mudhari Mansoob?`}</strong></p>
    <br />
    <p>{`Lets find out in`}</p>
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`نواصب المضارع`}</strong></h2>
    <p>{`نواصب المضارع are 10`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أن`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`لن`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`إذن`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`كي`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-green"
        }}>{`(لام التعليل (لام كي`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-cyan"
        }}>{`لام الجحود`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-cyan"
        }}>{`حتى`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-cyan"
        }}>{`فاء السببية`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-cyan"
        }}>{`واو المعية`}</strong></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-cyan"
        }}>{`أو`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "20.245398773006134%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAzUlEQVQI102Ky03DQBRFpwNqo4uARAEpgEVqoAEWRDSABM6CVRaECCsKkh1HcWwTM783nzczHhtFRJCju7i695B+GH5zTuyHt1zOFrvX5X6RiXi8z5VTJ/9D3w8xdiF0iFbptjoU+S7fNmXFQGijjfc+xqP1B/lwtkT7BLzRkAIvDaSSb6RIlMpCxMA+m+W6fi/bgrU1owfJvzRQZ8GjIJeG31lF+HcS8EbQB6vGQO/RXFA2wjjPprcvV5Pk+rl47GTh9B75ysnMw8bx9AcvgNtkjn1YOQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "10 of Nawasib of Mudhari",
          "title": "10 of Nawasib of Mudhari",
          "src": "/static/4b81a01e1bcb75fe0fe5a75028eeff71/a6d36/10-nawasib-mudhari.png",
          "srcSet": ["/static/4b81a01e1bcb75fe0fe5a75028eeff71/222b7/10-nawasib-mudhari.png 163w", "/static/4b81a01e1bcb75fe0fe5a75028eeff71/ff46a/10-nawasib-mudhari.png 325w", "/static/4b81a01e1bcb75fe0fe5a75028eeff71/a6d36/10-nawasib-mudhari.png 650w", "/static/4b81a01e1bcb75fe0fe5a75028eeff71/e548f/10-nawasib-mudhari.png 975w", "/static/4b81a01e1bcb75fe0fe5a75028eeff71/6acbf/10-nawasib-mudhari.png 1001w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Kufi group say, `}</p>
    <blockquote>
      <p parentName="blockquote">{`All above 10 الكلمات make Fi'l Mudhari Mansoob`}</p>
    </blockquote>
    <br />
    <p>{`Basri group say,`}</p>
    <blockquote>
      <p parentName="blockquote">{`Only 4 الكلمات (highlighted in yellow) make Fi'l Mudhari Mansoob. For rest 6 الكلمات there is a presence of Hidden أن, i.e., أن مضمرة which make Fi'l Mudhari Mansoob. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Green Category`}</strong>{` is made Mansoob with أن مضمرة جوازاً implying we can put أن in the sentence or can hide it.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Cyan Category`}</strong>{` is made Mansoob with أن مضمرة وجوبا implying we `}<em parentName="p">{`CAN NOT`}</em>{` put أن in the sentence.`}</p>
    </blockquote>
    <br />
    <p>{`Let's look into each harf, beginning with`}</p>
    <h3>{`أن`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`أن is حرف نصب ومصدر واستقبال`}</p>
    </blockquote>
    <br />
    <ul>
      <li parentName="ul">{`It is called مصدر because we can replace أن + الفعل with Masdar Muawwal ( مصدر مؤول)  `}
        <ul parentName="li">
          <li parentName="ul">{`Example: أريد `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أن أطلبَ`}</strong>{` العلم can be replaced with أريد `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`طلبَ`}</strong>{` العلم`}</li>
        </ul>
      </li>
      <li parentName="ul">{`It is called استقبال because we can use it with الفعل to mean an event about the future.`}</li>
    </ul>
    <h3>{`لن`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`لن is حرف نفي ونصب واستقبال  `}</p>
    </blockquote>
    <br />
    <p>{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لن`}</strong>{` أقرأَ الكتاب`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أقرأ`}</strong>{`
فعل مضارع منصوب ب (لن) وعلامة نصبه الفتحة`}</p>
    </blockquote>
    <h3>{`كي`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`كي is حرف نصب ومصدر واستقبال  `}</p>
    </blockquote>
    <p>{`Example: جئت `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`كي`}</strong>{` أتعلمَ اللغة`}</p>
    <br />
    <p>{`Similar to ان we have كي + الفعل = مصدر مؤول`}<br parentName="p"></br>{`
`}{`Above sentence can be replaced with جئت `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لتعلم`}</strong>{` اللغة Or جئت `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لكي`}</strong>{` أتعلمَ اللغة. Where the ل is called لام تعليل `}</p>
    <h3>{`إذن`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`إذن is حرف جواب وجزاء`}</p>
    </blockquote>
    <br />
    <p>{`We usually don't use this harf.`}</p>
    <br />
    <p>{`It is called جواب because we don't start our speech with إذن`}<br parentName="p"></br>{`
`}{`An example in the following conversation, `}</p>
    <ul>
      <li parentName="ul">{`سأزورك غداً`}</li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`إذن`}</strong>{` أكرمَك`}</li>
    </ul>
    <br />
    <p>{`Conditions (شروط) of إذن`}</p>
    <ul>
      <li parentName="ul">{`It has to come as the first word of a sentence of reply. (أن تكون واقعة في أول جملة الجواب)`}</li>
      <li parentName="ul">{`The verb that comes after it has to have the meaning of future (أن أن يكون الفعل بعدها يدل على الاستقبال)`}</li>
      <li parentName="ul">{`Nothing should come between إذن and the verb (ألا يفصل شيء بينها وبين الفعل)`}</li>
    </ul>
    <h3>{`لام التعليل`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`لام التعليل is حرف تعليل ونصب`}</p>
    </blockquote>
    <p>{`Example: جئت `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لأطلبَ`}</strong>{` العلم`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أطلبَ`}</strong>{`
Kufi Irab: فعل مضارع منصوب ب (لام التعليل) وعلامة نصبه الفتحة`}<br parentName="p"></br>{`
`}{`Basri Irab: فعل مضارع منصوب ب (أن) مضمرة جوازاً وعلامة نصبه الفتحة`}<br parentName="p"></br>{`
`}{`جوازاً implies sometimes we hide أن and sometimes we pronounce أن`}</p>
    </blockquote>
    <h3>{`لام الجحود`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`لام الجحود is حرف نصب و نفي (where الجحود = النفي)`}</p>
    </blockquote>
    <p>{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`ما كان`}</strong>{` المسلم `}<strong parentName="p">{`ل**`}</strong>{`يشربَ**{: .bg-yellow} الخمر(It is not befitting of a Muslim to drink alcohol). This sentence has very strong negation.  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`ما كان (لم يكن) + لام الحجود + الفعل المضارع`}</code></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`يشرب`}</strong>{`
Kufi Irab: فعل مضارع منصوب ب (لام الجحود) وعلامة نصبه الفتحة`}<br parentName="p"></br>{`
`}{`Basri Irab: فعل مضارع منصوب ب (أن) مضمرة وجوباً وعلامة نصبه الفتحة`}<br parentName="p"></br>{`
`}{`وجوباً implies we can't pronounce أن in the sentence`}</p>
    </blockquote>
    <br />
    <p>{`Quranic Example:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ما كان`}</strong>{` `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ل`}</strong>{`يأخذَ أخاه في دين الملك`}</li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`لم يكن`}</strong>{` الله `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ل`}</strong>{`يغفرَ لهم`}</li>
    </ul>
    <h3>{`حتى`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`حتى is (حرف نصب وتعليل أو بمعنى (إلى أن  `}</p>
    </blockquote>
    <p>{`Example: جئت حتى `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`أطلبَ`}</strong>{` العلم`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أطلبَ`}</strong>{`
Kufi Irab: فعل مضارع منصوب ب (حتى) وعلامة نصبه الفتحة`}<br parentName="p"></br>{`
`}{`Basri Irab: فعل مضارع منصوب ب (أن) مضمرة وجوباً وعلامة نصبه الفتحة`}<br parentName="p"></br>{`
`}{`وجوباً implies we can't pronounce أن in the sentence`}</p>
    </blockquote>
    <br />
    <p>{`Actually, حتى has two meanings`}</p>
    <ul>
      <li parentName="ul">{`حتى in the meaning of إلى أن`}
        <ul parentName="li">
          <li parentName="ul">{`Example: وكلوا واشربوا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`حتى`}</strong>{` يتبينَ الخيط الأبيض`}</li>
        </ul>
      </li>
      <li parentName="ul">{`حتى in meaning of كي`}
        <ul parentName="li">
          <li parentName="ul">{`Example: أفأنت تكره الناس `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`حتى`}</strong>{` يكونوا مؤمنين`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`فاء السببية`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`فاء السببية is حرف نصب`}</p>
    </blockquote>
    <br />
    <p>{`We call it السببية because what is before ف (e.g., a verb), is a reason for the occurrence (or happening) of what comes after ف i.e. `}</p>
    <blockquote>
      <p parentName="blockquote">{`بمعنى أن ما قبلها سبب في حصول ما بعدها`}</p>
    </blockquote>
    <br />
    <p>{`For Example in ذاكرْ `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`ف`}</strong>{`تنجحَ we mean `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Studying is the reason for Success`}</code></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`تنجح`}</strong>{`
Kufi Irab: فعل مضارع منصوب ب (الفاء) وعلامة نصبه الفتحة`}<br parentName="p"></br>{`
`}{`Basri Irab: فعل مضارع منصوب ب (أن) مضمرة وجوباً وعلامة نصبه الفتحة  `}</p>
    </blockquote>
    <br />
    <p>{`Quranic example: ولا تتبعوا السبل `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`ف`}</strong>{`تفرقَ بكم عن سبيله`}</p>
    <br />
    <p>{`Remember فاء السببية works as حرف نصب when it is preceded with following `}<strong parentName="p">{`Nine`}</strong>{` cases, viz`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`الأمر`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`ذاكر`}</strong>{` فتنجحَ`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`الدعاء`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example:`}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`اللهم تب علي`}</strong>{` فأدخلَ الجنة`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`النهي`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`ولا تتبع الهوى`}</strong>{` فيضلَّك عن سبيله`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`السؤال`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`هل ذاكرت`}</strong>{` فتنجحَ؟`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`العرض`}</strong>{` (It means when we offer something, e.g., to offer to one's friend)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`ألا تزورني`}</strong>{` فأكرمَك`}</li>
              <li parentName="ul"><strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`ألا تدنو`}</strong>{` فتبصرَ`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`الحضّ`}</strong>{` (It means when we offer something with some force, e.g. parents to his child)`}
        <ul parentName="li">
          <li parentName="ul">{`Example:`}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`هلَّا ذاكرت`}</strong>{` فتنجحَ`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`التمني`}</strong>{` (Hope for something Impossible OR scarce to happen)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`يا ليتني كنت معهم`}</strong>{` فأفوزَ فوزاً عظيماً`}</li>
              <li parentName="ul"><strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`ليت الشبابَ يعود يوماً`}</strong>{` فأخبرَه بما فعل المشيب`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`الرجاء`}</strong>{` (Hope for something possible)`}
        <ul parentName="li">
          <li parentName="ul">{`Example:`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`لعل الله يغفر لي`}</strong>{` فأدخلَ الجنة`}</li>
              <li parentName="ul"><strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`لعله يزكى .. أو يذكر`}</strong>{` فتنفعَه`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`النفي`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example:`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`لا أنام متأخراً`}</strong>{` فأستيقظَ متأخراً`}</li>
              <li parentName="ul"><strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`لايقضى عليهم`}</strong>{` فيموتوا`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`All of the above rules are present in the following poetry`}</p>
    <blockquote>
      <p parentName="blockquote">{`مر وادع وانه وسل واعرض لحضهم`}<br parentName="p"></br>{`
`}{`تمن وارج كذاك النفي قد كملا`}</p>
    </blockquote>
    <br />
    <p>{`We can also say,`}</p>
    <blockquote>
      <p parentName="blockquote">{`قبل فاء السببية يجب أن يكون هناك `}<strong parentName="p">{`نفي`}</strong>{` أو `}<strong parentName="p">{`طلب`}</strong></p>
    </blockquote>
    <br />
    <p>{`If we don't see any of the above 9 reasons, then فاء will not work as حرف نصب. For example in يقرأ زيد `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`فيقرأُ`}</strong>{` عمرو`}</p>
    <h3>{`واو المعية`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`واو المعية is (حرف عطف بمعنى (مع`}</p>
    </blockquote>
    <br />
    <p>{`For example لا أقرأُ `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`و`}</strong>{` أشاهدَ التلفاز (I don't read while watching TV)`}</p>
    <br />
    <p>{`Also, similar to فاء السببية we need to have similar 9 reasons (mentioned above) for واو to behave like حرف نصب`}</p>
    <h3>{`أو`}</h3>
    <br />
    <blockquote>
      <p parentName="blockquote">{`أو acts as حرف نصب with two meanings`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`إلى أن`}
        <ul parentName="li">
          <li parentName="ul">{`Example: لأستسهلن الصعب `}<strong parentName="li">{`أو`}</strong>{` أدركَ المنى (Any problematic thing that I will face, I will make it easy until I reach my dreams)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`إلَّا أن`}
        <ul parentName="li">
          <li parentName="ul">{`Example: لأقتلن الكافر أو يسلمَ`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`If we see أو with any other meaning, it will not act as حرف نصب`}</p>
    <br />
    <p>{`Moving on let's look into `}</p>
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`الأفعال الخمسة`}</strong></h2>
    <p>{`الأفعال الخمسة are 5 special verb forms when ا or و or ي is added to Fi'l Mudhari.`}</p>
    <blockquote>
      <p parentName="blockquote">{`كل فعل مضارع اتصل به ألف الاثنين أو واو الجماعة أو ياء المخاطبة`}</p>
    </blockquote>
    <br />
    <p>{`They are as follows`}</p>
    <ul>
      <li parentName="ul">{`يفعلان`}</li>
      <li parentName="ul">{`تفعلان`}</li>
      <li parentName="ul">{`يفعلون `}</li>
      <li parentName="ul">{`تفعلون `}</li>
      <li parentName="ul">{`تفعلين`}</li>
    </ul>
    <br />
    <p>{`I'rab of الأفعال الخمسة is done as`}</p>
    <ul>
      <li parentName="ul">{`ترفع بثبوت النون`}
        <ul parentName="li">
          <li parentName="ul">{`Example: الطلاب `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`يكتبون`}</strong>{` الدرس  `}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يكتبون`}</strong>{`
فعل مضارع مرفوع وعلامة رفعه ثبوت النون نيابة عن الضمة لأنه من الأفعال الخمسة`}<br parentName="p"></br>{`
`}{`واو الجماعة ضمير مبني في محل رفع فاعل`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`تنصب بحذف النون`}
        <ul parentName="li">
          <li parentName="ul">{`Example: الطلاب لن `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`يكتبوا`}</strong>{` الدرس  `}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يكتبوا`}</strong>{`
فعل مضارع منصوب وعلامة نصبه حذف النون نيابة عن الفتحة لأنه من الأفعال الخمسة`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`تجزم بحذف النون`}
        <ul parentName="li">
          <li parentName="ul">{`Example: الطلاب لم `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`يكتبوا`}</strong>{` الدرس  `}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`يكتبوا`}</strong>{`
فعل مضارع مجزوم وعلامة جزمه حذف النون نيابة عن السكون لأنه من الأفعال الخمسة`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p">{`Note:`}</strong>{` Pronoun with الأفعال الخمسة is always Marfoo and is not hidden`}</p>
    <blockquote>
      <p parentName="blockquote">{`الضمير مع الأفعال الخمسة دائما مرفوع وليس هناك ضمير مستتر  `}</p>
    </blockquote>
    <p>{`For example:`}</p>
    <ul>
      <li parentName="ul">{`يكتب`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`و`}</strong>{`ن here الواو is فاعل`}</li>
      <li parentName="ul">{`يُضرَب`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`و`}</strong>{`ن here الواو is نائب فاعل`}</li>
      <li parentName="ul">{`يكون`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`و`}</strong>{`ن here الواو is اسم كان`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Last but not the least let's look into what makes Fi'l Mudhari Majzoom`}</strong></p>
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`جوازم المضارع`}</strong></h2>
    <p>{`جوازم المضارع are divided into two categories`}</p>
    <ul>
      <li parentName="ul">{`كلمات which affect only one Fi'l Mudhari (كلمات تجزم فعلاً واحداً)`}</li>
      <li parentName="ul">{`كلمات which affect two Fi'l Mudhari (كلمات تجزم فعلين)`}</li>
    </ul>
    <br />
    <p>{`Let's look into each, beginning with `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`كلمات تجزم فعلاً واحداً:`}</strong>{` These are four كلمات, viz`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`لم`}</strong><br parentName="p"></br>{`
`}{`لم is حرف نفي وجزم وقلب`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لم`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`يكتبْ`}</strong>{` زيد الدرس, here لم only affected يكتبْ`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`لمَّا`}</strong><br parentName="p"></br>{`
`}{`لمَّا is حرف نفي وجزم وقلب`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لمَّا`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`يأتِ`}</strong>{` زيد (Zaid didn't come `}<strong parentName="p">{`yet`}</strong>{`)`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`لام الأمر`}</strong><br parentName="p"></br>{`
`}{`لام الأمر is حرف للطلب`}<br parentName="p"></br>{`
`}{`Example: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`لِ`}</strong>{`يُنفقْ ذو سعة`}</li>
      <li parentName="ul">{`اللهم `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ل`}</strong>{`تغفرْ لي here we call this لام as لام الدعاء`}</li>
    </ul>
    <br />
    <p>{`لام الأمر comes with Kasra but if و or ف or ثمّ comes before it then becomes Sakin, e.g. ف`}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لْ`}</strong>{`يعبدوا`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`لا الناهية`}</strong><br parentName="p"></br>{`
`}{`لا الناهية is حرف لطلب تَرْك الشيء`}<br parentName="p"></br>{`
`}{`Example:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`لا`}</strong>{` `}<strong parentName="li" {...{
          "className": "bg-cyan"
        }}>{`تكتبْ`}</strong>{` درسك`}</li>
      <li parentName="ul">{`ربنا `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`لا`}</strong>{` `}<strong parentName="li" {...{
          "className": "bg-cyan"
        }}>{`تُزِغْ`}</strong>{` قلوبنا here we call this لا as لا للدعاء`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "561px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "44.171779141104295%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUklEQVQoz12RbU/CMBDH9/2/h69MDCFRX0liNI6noAJuRoc6UWBhsHVb18e7eQOCQHNpr//+7q7tOdXxKIQNYx3M8vEkGnjfwyAa+NPJnIdLnZWWADyAnb2HGznOzMsMXW95ft1t3Dxe3nlnzfu2tyQxYmaPnQZvh1AQMTtP1Otn1BsGZP7HYraSJJYKTmBnk+wgG6JWUopSlGRCClo5zSQiwgGFR5WttUIIzrnWBgCKgtcEVuQYa0mkIwII+69M+Yoi47ygSsbUrwJrkmS1hwBsuqatJp+AukDB85xVCA7mQxn3dDqqcPMfOrFsoNY9lNNdfjlXq65J+qjirWDyNxl3qnzkTP1m8HzBZvcVqDpYRov3q3DcUIm3a4z4yn5bIrqt1LxWACrusZ+WWbuOih9U7AJ7QqgvBmqpVy4Z8Mnu2mUIaQfSNsrFtlWY+xSJrP8HDMEA/iDYZ+gAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "4 of Jawazim of Mudhari",
          "title": "4 of Jawazim of Mudhari",
          "src": "/static/66a94a17869f85ed943b950e27e3abef/410f3/4-jawazim-mudhari.png",
          "srcSet": ["/static/66a94a17869f85ed943b950e27e3abef/222b7/4-jawazim-mudhari.png 163w", "/static/66a94a17869f85ed943b950e27e3abef/ff46a/4-jawazim-mudhari.png 325w", "/static/66a94a17869f85ed943b950e27e3abef/410f3/4-jawazim-mudhari.png 561w"],
          "sizes": "(max-width: 561px) 100vw, 561px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`كلمات تجزم فعلين:`}</strong>{` These are eleven كلمات, viz`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`إن`}</strong><br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`إن`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تذاكرْ`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`تنجحْ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`إن`}</strong>{`
حرف شرط جازم مبني لا محل له من الإعراب`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`تذكر`}</strong>{`
فعل مضارع مجزوم ب (إن) وعلامة جزمه السكون وهو فعل الشرط`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`تنجح`}</strong>{`
فعل مضارع مجزوم ب (إن) وعلامة جزمه السكون وهو جواب الشرط`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`إذما`}</strong><br parentName="p"></br>{`
`}{`إذما is just like إن`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`إذما`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تذاكرْ`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`تنجحْ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`تذكر`}</strong>{`
فعل مضارع مجزوم ب (إذما) وعلامة جزمه السكون وهو فعل الشرط`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`تنجح`}</strong>{`
فعل مضارع مجزوم ب (إذما) وعلامة جزمه السكون وهو جواب الشرط`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`من`}</strong><br parentName="p"></br>{`
`}{`من is اسم شرط للعاقل`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`من`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تذاكرْ`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`تنجحْ`}</strong></p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`ما`}</strong><br parentName="p"></br>{`
`}{`ما is اسم شرط لغير العاقل`}<br parentName="p"></br>{`
`}{`Example: و`}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`ما`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تفعلوا`}</strong>{` من خير `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`يعلم`}</strong>{`ه الله`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`مهما`}</strong><br parentName="p"></br>{`
`}{`مهما is اسم شرط لغير العاقل and behaves similar to ما`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`مهما`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تفعل`}</strong>{` من خير `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`يعلم`}</strong>{`ه الله`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`متى`}</strong><br parentName="p"></br>{`
`}{`متى is اسم شرط للزمان`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`متى`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تذهب`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`أذهب`}</strong>{` معك (when you go, I will go with you)`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`أيان`}</strong><br parentName="p"></br>{`
`}{`أيان is اسم شرط للزمان and behaves similar to متى`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`أيان`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تذهب`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`أذهب`}</strong>{` معك (when you go, I will go with you)`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`أين`}</strong><br parentName="p"></br>{`
`}{`أين is اسم شرط للمكان`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`أين`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تذهب`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`أذهب`}</strong>{` معك (where you go, I will go with you)`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`أنى`}</strong><br parentName="p"></br>{`
`}{`أنى is اسم شرط للمكان and behaves similar to أنى`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`أنى`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تذهب`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`أذهب`}</strong>{` معك (where you go, I will go with you)`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`حيثما`}</strong><br parentName="p"></br>{`
`}{`حيثما is اسم شرط للمكان and behaves similar to أنى`}<br parentName="p"></br>{`
`}{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`حيثما`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`تذهب`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-green"
      }}>{`أذهب`}</strong>{` معك (where you go, I will go with you)`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`أيّ`}</strong><br parentName="p"></br>{`
`}{`أيّ is اسم شرط معرب`}</p>
    <br />
    <p><strong parentName="p">{`Characteristics of أيّ`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`All كلمات الشرط are مبنية except أيّ which is معرب`}</li>
      <li parentName="ul">{`أيّ is always مضافة`}</li>
      <li parentName="ul">{`We can use it for عاقل`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أيَّ`}</strong>{` طالبٍ `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`يذاكرْ`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-green"
            }}>{`ينجحْ`}</strong></li>
        </ul>
      </li>
      <li parentName="ul">{`We can use it for غير العاقل`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أيّ`}</strong>{` كتابٍ `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`تقرأْ`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-green"
            }}>{`أقرأ`}</strong></li>
        </ul>
      </li>
      <li parentName="ul">{`We can use it for زمان`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أيَّ`}</strong>{` يومٍ `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`تدرسْ`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-green"
            }}>{`أدرسْ`}</strong>{` معك`}</li>
        </ul>
      </li>
      <li parentName="ul">{`We can use it for مكان`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أيَّ`}</strong>{` مكانٍ `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`تذهبْ`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-green"
            }}>{`أذهبْ`}</strong>{` معك`}</li>
        </ul>
      </li>
      <li parentName="ul">{`We can also add ما after أي i.e. (أحياناً نزيد (ما) بعد أي)`}
        <ul parentName="li">
          <li parentName="ul">{`Quranic Example: أيَّما الأجلين قضيت`}</li>
          <li parentName="ul">{`Quranic Example: أيَّاًما تدعوا فله الأسماء الحسنى`}</li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "26.993865030674847%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxklEQVQY011O7WoCQQzc93+cvkBpaRHO9kc/KYKI/vBw9dyPJLvJnu4ZjwrqkISZzARihlvUcUYepn/26X3+2MyUgJydOtzDXPLXVgWS2XL70ny+Tr+VQJK7wDiq+Ve19ocDSwFE54NzzjvHOTNnf6aqAyKJ9BqrlyeMpE6YMFjt4NpMkWAnDEVLsIxEGHWZ0Ee3JowQrJ4IWQPt81E62LyVtNmtJn2yqfvguKDuJ/u5Fmy/UliJ/+1pSXZSyz60zbH4/eLhBFeFHSzkEaJwAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "11 of Jawazim of Mudhari",
          "title": "11 of Jawazim of Mudhari",
          "src": "/static/e0d2c4698c0affefda78447da2bf0432/a6d36/jawazim-mudhari.png",
          "srcSet": ["/static/e0d2c4698c0affefda78447da2bf0432/222b7/jawazim-mudhari.png 163w", "/static/e0d2c4698c0affefda78447da2bf0432/ff46a/jawazim-mudhari.png 325w", "/static/e0d2c4698c0affefda78447da2bf0432/a6d36/jawazim-mudhari.png 650w", "/static/e0d2c4698c0affefda78447da2bf0432/0fcea/jawazim-mudhari.png 851w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`As we have briefly looked into Jumla Shartiya for كلمات تجزم فعلين, Let's cover the `}</p>
    <br />
    <h3>{`Rules of using ف with Jawab Shart`}</h3>
    <p>{`All the cases where we have to add ف in Jawab Shart is summarised in the following poem,`}</p>
    <blockquote>
      <p parentName="blockquote">{`اسميةٌ طلبيةٌ وبجَامد  وبما وقد وبِلَنْ وبالتَّنْفِيْسِ`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 1:`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`جملة اسمية`}</strong><br parentName="p"></br>{`
`}{`When Jawab shart is a Jumla Ismiya, we have to add ف.`}<br parentName="p"></br>{`
`}{`For example, وإن يمسسك بخير `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`ف`}</strong>{`هو على كل شيء قديرٌ`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الفاء`}</strong>{`
واقعة في جواب الشرط حرف لا محل له من الإعراب`}<br parentName="p"></br>{`
`}{`OR`}<br parentName="p"></br>{`
`}{`فاء الربط (رابطة) حرف لا محل له من الإعراب.`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 2:`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`جملة فعلية طلبية`}</strong><br parentName="p"></br>{`
`}{`When Jawab shart is a جملة فعلية and فعلها طلبي, we have to add ف.`}<br parentName="p"></br>{`
`}{`For example, قل إن كنتم تحبون الله `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`ف`}</strong>{`اتبعوني`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 3:`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`جملة فعلية فعلها جامد`}</strong><br parentName="p"></br>{`
`}{`When Jawab shart is a جملة فعلية and فعلها جامد, we have to add ف.`}<br parentName="p"></br>{`
`}{`For example,`}</p>
    <ul>
      <li parentName="ul">{`ومن يفعل ذلك `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ف`}</strong>{`ليس من الله في شيء`}</li>
      <li parentName="ul">{`إن ترن أنا أقل منك مالاً وولداً `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ف`}</strong>{`عسى ربي أن يؤتيني خيراً من جنتك`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 4:`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`(أن تبدأ الجملة  بنفي (ما – لن`}</strong><br parentName="p"></br>{`
`}{`When Jawab shart starts with ما or لن, we have to add ف.`}<br parentName="p"></br>{`
`}{`For example,`}</p>
    <ul>
      <li parentName="ul">{`وما يفعلوا من خير `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ف`}</strong>{`لن يكفروه`}</li>
      <li parentName="ul">{`وإن لم تفعل `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ف`}</strong>{`ما بلغت رسالته`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 5:`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`أن تبدأ الجملة  بقد`}</strong><br parentName="p"></br>{`
`}{`When Jawab shart starts with قد, we have to add ف.`}<br parentName="p"></br>{`
`}{`For example, إن يسرق  `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`ف`}</strong>{`قد سرق أخ له من قبل`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 6:`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`أن تبدأ الجملة  ب (السين أو سوف)  وهذا هو التنفيس`}</strong><br parentName="p"></br>{`
`}{`When Jawab shart starts with السين or سوف (which is the meaning of التنفيس), we have to add ف.`}<br parentName="p"></br>{`
`}{`For example,`}</p>
    <ul>
      <li parentName="ul">{`ومن يستنكفْ عن عبادته ويستكبر `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ف`}</strong>{`سيحشرهم إليه جميعاً`}</li>
      <li parentName="ul">{`ومن يفعل ذلك عدوانا وظلماً  `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ف`}</strong>{`سوف نصليه ناراً`}</li>
    </ul>
    <br />
    <p>{`That's quite a lot for Fi'l Mudhari as of now.`}</p>
    <br />
    <p>{`Happy Learning :)`}</p>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      